import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-newsletter',
  templateUrl: './inner-newsletter.component.html',
  styleUrls: ['./inner-newsletter.component.css']
})
export class InnerNewsletterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
